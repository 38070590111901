<template>
    <public-frame :menu="false" :theme="dark?'':'white'">
        <div class="page-body">
            <div class="bg-white body-container d-flex flex-column ctr">
                <div class="information-row d-flex ">
                    <div class="cover">
                        <img :src="single.picture_url" class="responsive-image" alt="" style="border-radius: 5px;">
                    </div>
                    <div class="information ml-3 d-flex" style="flex-direction: column;flex:1">
                        <div class="title">{{ single.subject }}</div>
                        <div class="mt-2 text">{{ single.teacher_name }}</div>
                        <div class="mt-2 text">{{ single.good_time }}</div>
                        <div class="mt-2 text">{{ english ? single.city_english : single.city_name }} |
                            {{ english ? single.department_english : single.department_name }}
                        </div>
                        <div class="mt-2 text" style="height: 23px;">
                            {{ $t('MESSAGE.EVENT-START') }} <span class="text-info"> {{ countdown_string }} </span>
                        </div>
                        <div>
                            <el-button type="primary" class="mt-3" style="font-size: 18px;"
                                       icon="el-icon-caret-right"
                                       :disabled="!time_up" @click="goPlayPage">
                                {{ $t('TITLE.ENTER-LIVE') }}
                            </el-button>
                        </div>
                    </div>
                    <div style="flex: 0 100px; align-self: baseline;margin-top: 50px;">
                        <qrcode-vue :value="single.play_url" :size="100" level="H" />
                    </div>
                </div>
                <div class="line mt-3 mb-3 flex-grow-0"></div>
                <div class="flex-grow-1 overflow-auto">
                    <div><b>{{ $t('TITLE.DESCRIPTION') }}</b></div>
                    <div class="mt-3" v-html="single.description"></div>
                    <div v-if="single.desc_picture_url">
                        <img :src="single.desc_picture_url" class="responsive-image" alt=""
                             style="border-radius: 5px;">
                    </div>
                    <div class="line mt-3 mb-3 flex-grow-0"></div>
                    <div class="mt-3"><b>{{ $t('TITLE.SPEAKER') }}:</b></div>
                    <div class="mt-3">
                        <div v-for="x in single.teachers" :key="x.id" class="d-flex mb-3 bg-light p-4">
                            <div
                                class="teacher-image"
                                style="flex:0 0 100px;min-height:100px;background-size: cover;background-repeat: no-repeat; border-radius: 5px;"
                                :style="{backgroundImage:'url('+x.picture_url+')'} "></div>
                            <div class="flex-grow-1 ml-3">
                                <b>{{ x.name }}</b>
                                <div class="mt-1" v-html="x.bio"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </public-frame>
</template>

<script>
import PublicFrame from "@/components/public-frame-dark";
import CommonMixin from "@/common/mixins/common-mixin"
import QrcodeVue from 'qrcode.vue'


export default {
    name: "play-waiting-page",
    components: {PublicFrame,QrcodeVue},
    mixins: [CommonMixin],
    data() {
        return {
            passcode: "",
            time_up: false,
            timer: 0,
            countdown_string: '',
            old_stamp: 0,
        }
    },
    props: {
        dark: {
            type: Boolean, default() {
                return false;
            }
        },
        single: {
            type: Object,
            default() {
                return {};
            }
        },

    },
    mounted() {
        const vm = this;
        this.timer = setInterval(function () {
            vm.countdown();
        }, 1000);
        vm.countdown();
    },
    beforeDestroy() {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = 0;
        }
    },
    methods: {
        countdown() {
            const now = Math.floor(new Date().getTime() / 1000 + this.single.time_diff);
            if (now > this.single.start_timestamp) {
                this.countdown_string = this.$t('MESSAGE.ON-LIVE');
                this.time_up = true;
                clearInterval(this.timer); //不需要再去等待了。
                this.timer = 0;
            } else {
                let diff = this.single.start_timestamp - now;
                //console.log('WAIT time', now, this.single.start_timestamp, this.single.time_diff);
                const data = [0, 0, 0, 0];
                data[3] = diff % 60;
                diff = Math.floor(diff / 60);
                data[2] = diff % 60;
                diff = Math.floor(diff / 60);
                data[1] = diff % 24;
                diff = Math.floor(diff / 24);
                data[0] = diff;

                let zero = true;
                let string = "";
                let quantifier = {en: ['day', 'hour', 'minute', 'second'], cn: ['天', '小时', '分', '秒']};
                for (let i = 0; i < 4; i++) {
                    if (data[i] > 0 || !zero) {
                        string += data[i] + ' ' + (this.english ? quantifier.en[i] + (data[i] > 1 ? 's' : '') : quantifier.cn[i]) + ' ';
                        zero = false;
                    }
                }
                this.$set(this, 'countdown_string', string);
            }
        },
        goPlayPage() {
            this.$emit('quit');
        }
    },
}
</script>

