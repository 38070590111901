<template>
    <div class="video-play">
        <video :ref="player_name" class="video-js vjs-big-play-centered"
               autofocus autoplay controls preload="auto"
               style="object-fit: contain;height: 100%;width:100%; position: absolute">
        </video>
    </div>
</template>

<script>
import FlvExtend from "flv-extend"
import 'video.js/dist/video-js.css'

window.HELP_IMPROVE_VIDEOJS = false;

export default {
    name: "VideoPlayer",
    props: {
        source: {
            type: String,
        },
        poster: {
            type: String,
        },
        autoplay: {
            type: Boolean
        },
        muted: {
            type: Boolean
        },
        controls: {
            type: Boolean,
        },
    },
    data() {
        return {
            flv: null,
            player: null,
            options: {
                autoplay: false,
                controls: this.controls,
                poster: this.poster,
                sources: [],
                muted: this.muted,
            },
            error: false,
            countdown: 0,
            countdown_timer: 0,
            countdown_interval: 5000,
            timer: 0,
            speed_count:0,
            player_name: "video_player" + (new Date().getTime()),
        }
    },
    watch: {
        source(newVal) {
            const vm = this;
            this.$nextTick(
                () => {
                    if (newVal) {
                        vm.setSource(newVal);
                    }
                }
            );
        }
    },
    mounted() {
        const vm = this;
        this.flv = new FlvExtend({
            element: this.$refs[this.player_name],
            frameTracking: false,
            updateOnStart: false,
            updateOnFocus: false,
            reconnect: false,
            reconnectInterval: 1000,
            showLog: false,

        })
        this.setSource(vm.source);

        // 获取video element
        const video = this.$refs[this.player_name];
        video.setAttribute('controls', this.controls ? 'true' : 'false');
        video.setAttribute('muted', this.muted ? 'true' : 'false');

        this.flv.onProgress = vm.handleProgress;
        this.flv.onError = vm.handleFlvError;

        // 如果是0需要考虑自动重连，
        this.flv.onStatisticsInfo = this.handleStatisticsInfo;
    },
    methods: {
        handleStatisticsInfo(e) {
            if (e.speed === 0) {
                this.speed_count += 1;
            } else {
                this.speed_count = 0;
            }
            if (this.speed_count > 15) {
                this.speed_count = 0;
                console.error('try play again!')
                this.handleFlvError();
            }
        },
        handleProgress(){
            if (this.countdown_timer && this.countdown > 0) {
                console.log('clear countdown timer');
                clearInterval(this.countdown_timer);
                this.countdown_timer = 0;
                this.countdown = 0;
            }
        },
        handleFlvError() {
            const vm= this;
            if (vm.countdown_timer) {
                clearInterval(vm.countdown_timer);
                vm.countdown_timer = 0;
            }
            vm.countdown_timer = setInterval(() => {
                console.error('try play again!');
                vm.flv.rebuild();
                vm.countdown = 1;
                vm.flv.onError = vm.handleFlvError;
                //vm.flv.onProgress = vm.handleProgress;
            }, vm.countdown_interval);
        },
        setSource(src) {
            // 将this.source 替换.m3u8 为 .flv, 替换live/为flv/live/
            const source = src.replace(/\.m3u8/, '.flv').replace(/live\//, 'flv/live/');
            this.player = this.flv.init({
                type: "flv",
                url: source,
                insLive: true
            }, {
                enableStashBuffer: false,
                stashInitialSize: 128,
            })
            this.player.play();
        },
        removeTimer() {
            if (this.timer) {
                clearInterval(this.timer);
                this.timer = 0;
            }
            if (this.countdown_timer) {
                clearInterval(this.countdown_timer);
                this.countdown_timer = 0;
            }
        }
    },
    beforeDestroy() {
        if (this.player) {
            this.player.destroy();
        }
        this.removeTimer();
    }
}
</script>

<style lang="scss">
.video-play-outer {
    position: relative;
    width: 100%;
    padding-top: 56.25%;

    .video-play-inner {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}
</style>
