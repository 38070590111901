<template >
    <div :class="type==='html' || type==='spacer'?'application-notice':'form-item'" class="position-relative">
        <div v-if="type==='html'">
            {{ placeholder }}
        </div>
        <div class="mt-3 pl-2 position-relative" v-if="type==='spacer'">
            {{ placeholder }}
            <div class="from-required-spacer" v-if="required">*</div>
            <slot></slot>
        </div>
        <div v-if="type==='text'">
            <nut-cell>
                <nut-textinput slot="title"
                               :value="value"
                               :placeholder="placeholder"
                               :label="title"
                               :clearBtn="!disabled"
                               :readonly="disabled"
                               @input="updateValue"
                               :has-border="false"
                />
            </nut-cell>
        </div>
        <div v-if="type==='date' && mobile ">
            <nut-cell
                :title="title"
                :desc="default_value"
                :show-icon="true"
                @click-cell="handleVisible"
            />
            <nut-calendar
                :is-visible.sync="visible"
                :default-value="default_value"
                :is-auto-back-fill="true"
                @choose="updateValue($event[3])"
            >
            </nut-calendar>
        </div>
        <div v-if="type==='date' && !mobile">
            <nut-cell :title="title">
                <nut-textinput slot="desc"
                               :value="value"
                               :readonly="disabled"
                               @input="updateValue"
                               type="date"
                               :clearBtn="false"
                />
            </nut-cell>
        </div>
        <div v-if="type==='time' && mobile">
            <nut-cell
                :title="title"
                :desc="default_value"
                :show-icon="true"
                @click-cell="handleVisible"
            />
            <nut-datepicker
                :is-visible="visible"
                type="time"
                :title="placeholder"
                :minute-step="15"
                :defaultValue="default_value"
                @close="visible=false"
                @choose="updateValue($event[2])"
            >
            </nut-datepicker>
        </div>
        <div v-if="type==='time' && !mobile">
            <nut-cell :title="title">
                <nut-textinput slot="desc"
                               :value="value"
                               :readonly="disabled"
                               @input="updateValue"
                               type="time"
                               :clearBtn="false"
                />
            </nut-cell>
        </div>
        <div v-if="type==='text2'">
            <nut-cell>
                <nut-textinput slot="title"
                               :value="value"
                               placeholder="请输入"
                               :label="title"
                               :clearBtn="!disabled"
                               :readonly="disabled"
                               @input="updateValue"
                />
                <div slot="sub-title">
                    <pre class="nut-form-pre">{{ placeholder }}</pre>
                </div>
            </nut-cell>
        </div>
        <div v-if="type==='textarea'">
            <nut-textbox slot="sub-title" :value="value"
                         style="font-size: 14px;font-weight: normal;"
                         :txtAreaH="100"
                         :max-num="300"
                         @input="updateValue"
                         :disabled="disabled"
            />
        </div>
        <div v-if="type==='switch'">
            <nut-cell :title="title">
                <nut-switch slot="desc"
                            :active="!!value"
                            :disabled="disabled"
                            @change="updateValue"
                />
            </nut-cell>
        </div>
        <div v-if="type==='select' && mobile">
            <nut-cell :title="title"
                      :show-icon="true"
                      @click-cell="handleVisible"
                      :desc="Array.isArray(default_value)?default_value.join(','):default_value"/>
            <nut-picker
                :is-visible="visible"
                :list-data="options"
                :default-value-data="value_array"
                @close="visible=false"
                @confirm="updateValue"
            ></nut-picker>
        </div>
        <div v-if="type==='select' && !mobile">
            <nut-cell :title="title"
                      @click-cell="handleVisible">
                <select v-model="default_value" slot="desc" @change="updateValue(default_value)"
                        style="font-size: 14px;border:none">
                    <option v-for="x in options[0]" :key="x" :value="x" :label="x"></option>
                </select>
            </nut-cell>
        </div>
        <div v-if="type==='picture'">
            <nut-cell :title="title" @click-cell="handleVisible" class="h-75">
                <picture-upload slot="desc" :list="value" @list="updateValue" :disabled="disabled"/>
                <div slot="sub-title">
                    {{ placeholder }}
                </div>
            </nut-cell>
        </div>
        <div class="from-required" v-if="fieldRequired || (required && type !=='spacer')">*</div>
    </div>
</template>
<script>
import mobileInput from "@/common/mobile-input";
import PictureUpload from "@/components/picture-upload.vue";

export default {
    name: "nut-form-editor",
    components: {PictureUpload},
    props: ['value', 'target', 'fields', 'disabled','required'],
    data() {
        return {
            visible: false,
            default_value: ''
        }
    },
    watch: {
        value: {
            handler: function (val) {
                this.default_value = val;
            },
            immediate: true
        }
    },
    computed: {
        value_array() {
            return [this.default_value];
        },
        type() {
            if (!this.fields) return 'spacer';
            return this.fields[this.target]?this.fields[this.target]['type']:undefined;
        },
        placeholder() {
            if (!this.fields) return this.target;
            return this.fields[this.target]['placeholder'];
        },
        title() {
            if (!this.fields) return this.target;
            return this.fields[this.target]['title'];
        },
        fieldRequired() {
            if (!this.fields || !this.fields[this.target] || !this.fields[this.target]['validation']) return false;
            if (this.disabled) return false;
            if (this.type === "spacer" || this.type === 'html' || this.type === 'textarea') return false;
            return this.fields[this.target]['validation'].indexOf('required') !== -1;
        },
        options() {
            if (!this.fields || !this.fields[this.target] || !this.fields[this.target]['options']) return [];
            const opts = this.fields[this.target]['options'];
            if (!Array.isArray(opts[0])) {
                return [opts];
            }
            return opts;
        },
        mobile() {
            return mobileInput() !== "";
        }
    },
    methods: {
        updateValue(event) {
            let ret = event;
            if (Array.isArray(ret)) {
                if (['select'].indexOf(this.type) !== -1) ret = ret[0];
            }

            this.default_value = ret;
            this.$emit('input', ret);
            this.visible = false;
        },
        handleVisible() {
            if (this.disabled) return;
            this.visible = true;
        }
    }
}
</script>

<style lang="scss">
.application-form {
    $--public-highlight: #000099;

    .application-notice {
        font-weight: bold;
        margin-bottom: 10px;
        padding: 10px;
    }

    .form-item {
        background-color: white;
        padding: 0 10px;

        & > div {
            border-top: 1px solid #eeeeee;
        }

        &:first-child > div {
            border-top: none;
        }

        .from-required {
            color: red;
            font-size: 12px;
            position: absolute;
            top: 18px;
            left: 2px;
        }


        .nut-cell-box {
            padding: 0;
            color: #333333;
            font-weight: bold;
            text-decoration: none;

            .nut-cell-desc {
                font-size: 14px;
                color: $--public-highlight;

                select {
                    color: $--public-highlight;
                    text-align: right;
                }
            }
        }

        .nut-cell:only-of-type {
            background: none;
        }

        .nut-textinput {
            height: 48px;

            .nut-textinput-label {
                color: #333333;
                font-weight: bold;
            }

            input {
                border: none;
                color: $--public-highlight;
                text-align: right;
            }
        }

        .nut-textbox {
            .txt-area {
                border: none;
                padding: 0;

                textarea {
                    color: $--public-highlight;
                }
            }
        }
    }
}

.application {
    .nut-dialog-box {
        max-width: 1200px;
        margin: auto;
    }
}



.nut-form-pre {
    color: #666;
    font-size: 13px;
    font-weight: normal;
    max-height: 100px;
    background-color: #fafbfc;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid #e1e4e8;
}

.from-required-spacer {
    color: red;
    font-size: 12px;
    position: absolute;
    top: 0;
    left: 0;
}

</style>
