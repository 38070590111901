<template>
    <div class="play-page">
        <div class="float-title"><i class="on-the-air" v-if="!closed"></i> {{ single.subject }}</div>
        <div class="panel-left">
            <div class="video-panel position-relative" ref="video_panel">
                <div class="video-poster" v-if="show_poster"
                     :style="{backgroundImage: 'url('+single.picture_url+')'}">
                    <div class="dvr-error flex-in-middle h-100" style="background-color: RGBA(0,0,0,0.6)"
                         v-if="closed && !single.dvr_file">
                        <h1 class="text-white">{{ $t('MESSAGE.DVR-NOT-READY') }}</h1>
                    </div>
                    <div class="dvr-error flex-in-middle h-100" style="background-color: RGBA(0,0,0,0.6)"
                         v-else-if="event_ended && !closed">
                        <div class="text-center">
                            <h1 class="text-white">{{ $t('MESSAGE.EVENT-CLOSED') }}</h1>
                            <el-button class="mt-3" style="font-size: 20px;" type="info" icon="el-icon-back"
                                       @click="$emit('quit')">{{ $t("MESSAGE.WATCH-DVR") }}
                            </el-button>
                        </div>
                    </div>
                    <div class="flex-in-middle h-100" v-else>
                        <el-button type="text" @click="show_poster=false">
                            <img src="~@/assets/images/play_icon.png" alt="" style="width: 300px;"/>
                        </el-button>
                    </div>
                </div>
                <template v-if="!show_poster">
                    <div class="video">
                        <div class="v-100 h-100" v-if="stream_url && !event_ended || closed">
                            <video-flv-player v-if="flv" :source="stream_url"
                                              :controls="!stream_url2 || closed"
                                              autoplay>
                            </video-flv-player>
                            <video-player :source="stream_url"
                                          :controls="!stream_url2 || closed"
                                          autoplay v-else>
                            </video-player>
                        </div>
                    </div>
                    <div class="panel-divider" @mousedown="dividerStartMove" v-if="stream_url2"></div>
                    <div class="video" ref="right" :style="{flex:'0 0 ' + right_size}" v-if="stream_url2">
                        <div class="v-100 h-100" v-if="stream_url && !event_ended || closed">
                            <video-flv-player v-if="flv" :source="stream_url2"
                                              autoplay>
                            </video-flv-player>
                            <video-player :source="stream_url2"
                                          autoplay v-else>
                            </video-player>
                        </div>
                    </div>
                </template>
            </div>

            <!-- Control Panel --->
            <div class="control-wrap">
                <div class="controls">
                    <div class="language">
                        <div class="title">{{ $t("TITLE.LANGUAGE") }}</div>
                        <div class="item-wrap">
                            <div class="item active" @click="languageSelect()">
                                {{ this.english ? "English" : "中文" }}
                            </div>
                        </div>
                    </div>
                    <div class="source" v-if="!closed">
                        <div class="title">{{ $t("TITLE.SOURCE") }}</div>
                        <div class="item-wrap">
                            <div class="item " v-for="x in edge" :key="x.name" :class="{active:source===x.english}"
                                 @click="sourceSelect(x.english)">
                                {{ english ? x.english : x.name }}
                            </div>
                        </div>
                    </div>
                    <div class="event-info">
                        <div class="title">
                            {{ single.good_time | time }} | {{ single.city_name }} | {{ single.department_name }} |
                            {{ single.teacher_name }} ｜
                            <el-button type="text" @click="description_window_visible=true" icon="el-icon-zoom-in"
                                       style="color:#9fcdff;">{{ $t('TITLE.DETAILS') }}
                            </el-button>
                        </div>
                        <div class="description" v-html="single.description">
                        </div>
                    </div>
                    <div class="statistic">
                        <div class="number-wrap text-center">
                            <div>
                                <div class="number">{{ single.online.online }}</div>
                                <el-popover popper-class="popover-event-link"
                                            :visible-arrow="false"
                                            placement="top-start"
                                            trigger="hover">
                                    {{ user.name }} | {{ user.department }} | {{ user.city }}
                                    <div slot="reference"><i class="el-icon-user"></i></div>
                                </el-popover>
                            </div>
                        </div>
                        <div class="event-link text-center">
                            <el-popover popper-class="popover-event-link"
                                        :visible-arrow="false"
                                        placement="top-start"
                                        trigger="hover">
                                <div class="text-center">
                                    <qrcode-vue :value="single.play_url" :size="240" level="H"
                                                style="display: inline-block"/>
                                </div>
                                {{ single.play_url }}
                                <div slot="reference"><i class="el-icon-link"></i></div>
                            </el-popover>
                        </div>
                    </div>
                    <div class="panel-switch" @click="toggleRight" v-if="!single.is_comment_disabled">
                        <i :class="right?'el-icon-arrow-right':'el-icon-arrow-left'"></i>
                    </div>
                </div>
            </div>
        </div>
        <!-- right panel -->
        <div class="panel-right" v-show="right" v-if="!single.is_comment_disabled && !hide_comment_window">
            <div class="panel-logo">
                <img :src="'/assets/panel-logo.png'" alt="logo" class="responsive-image">
            </div>
            <div class="panel-line "></div>
            <div class="panel-comments">
                <div ref="comment_window" v-show="tab===0" class="wh-100 p-1 comment-wrap">
                    <div v-for="x in comments" :key="x.time" class="comment" :class="{announce:x.announce}">
                        <section class="d-flex flex-space-between header" v-if="!x.announce">
                            <div class="name">{{ x.announce ? $t('TITLE.ANNOUNCE') : x.user_name }}</div>
                            <div class="time">{{ x.time | short_time }}</div>
                        </section>
                        <section class="body">
                            <div class="question">{{ x.comment }}</div>
                            <div class="answer" v-if="x.answer">{{ x.answer }}</div>
                        </section>
                    </div>
                </div>
                <div ref="self_window" v-show="tab===1" class="wh-100 p-1 comment-wrap">
                    <div v-for="x in self" :key="x.time" class="comment">
                        <section class="d-flex flex-space-between header">
                            <div class="name"><span style="color: #9dd" v-if="x.private === '1'"><i
                                class="el-icon-user-solid"></i> </span> {{ x.user_name }}
                            </div>
                            <div class="time">{{ x.time | short_time }}
                            </div>
                        </section>
                        <section class="body">
                            <div class="question">{{ x.comment }}</div>
                            <div class="answer" v-if="x.answer">{{ x.answer }}</div>
                        </section>
                    </div>
                </div>
            </div>
            <div class="panel-comments-tab">
                <div :class="{active:tab ===0 }" @click="switchTabComment">{{ $t("TITLE.COMMENTS") }}</div>
                <div :class="{active:tab ===1 }" @click="switchTabInput">{{ $t("TITLE.INPUTS") }}</div>
            </div>
            <div class="panel-foot" v-if="!closed && user.token && user.token.length">
                <!--
                <div class="flex-in-middle" style="height:30px;color: #999999; cursor: pointer;"
                     @click="question_private=!question_private">
                    <div class="tick" :class="{active:question_private}"></div>
                    <div class="ml-2 text hover-grow" style="font-size: 14px;">{{ $t("TITLE.PRIVATE-QUESTION") }}</div>
                </div>
                -->
                <div class="panel-foot-control-wrap">
                    <el-input type="textarea" clearable v-model="question" resize="none" :maxlength="150"></el-input>
                    <div>
                        <el-button type="dark" @click="sendComment" :disabled="!question" :loading="preset.loading">
                            {{ $t('TITLE.SEND') }}
                        </el-button>
                    </div>
                </div>
            </div>
            <div class="play-live-login" v-if="!closed && (!user.token || !user.token.length)">
                <div class="mt-2 text-center">
                    <el-button type="text" @click="jumpToSignIn" style="color:dodgerblue">
                        {{ $t('TITLE.SIGN-IN-TO-COMMENT') }}
                    </el-button>
                </div>
            </div>
        </div>
        <el-dialog :title="single.subject" :visible.sync="description_window_visible" width="75%"
                   :close-on-click-modal="true" class="dark">
<!--            <div class="mb-3">-->
<!--                <div class="text"><b>{{ single.good_time }} | {{ english ? single.city_english : single.city_name }} |-->
<!--                    {{ english ? single.department_english : single.department_name }} </b>-->
<!--                </div>-->
<!--            </div>-->
            <div v-html="single.description" class="dark-description"></div>
            <div v-if="single.desc_picture_url"><img :src="single.desc_picture_url" class="responsive-image"/></div>
            <div class="line mt-3 mb-3"></div>
            <div v-if="single.teacher_name != ''">
                <div class="mt-3"><b>{{ $t('TITLE.SPEAKER') }}:</b></div>
                <div class="mt-3">
                    <div v-for="x in single.teachers" :key="x.id" class="d-flex mb-3 bg-dark p-4">
                        <div
                            class="teacher-image"
                            style="flex:0 0 100px;min-height:100px;background-size: contain;background-repeat: no-repeat; border-radius: 5px;"
                            :style="{backgroundImage:'url('+x.picture_url+')'} "></div>
                        <div class="flex-grow-1 ml-3">
                            <b>{{ x.name }}</b>
                            <div class="mt-1" v-html="x.bio"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-3"><span style="vertical-align: top" class="mr-4"> <b>{{ $t('TITLE.QRCODE') }}: </b></span> <br><br>
                <qrcode-vue :value="single.play_url" :size="120" level="H"
                            style="display: inline-block"/>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="description_window_visible = false" type="dark" style="width: auto;">Close
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>

import CommonMixins from "@/common/mixins/common-mixin";
import "@/views/scss/play-live-page.scss";
import VideoPlayer from "../components/video-player";
import QrcodeVue from 'qrcode.vue';
import VideoFlvPlayer from "@/components/video-flv-player";

export default {
    name: "play-live-page",
    components: {VideoPlayer, QrcodeVue,VideoFlvPlayer},
    mixins: [CommonMixins],
    data() {
        return {
            tab: 0,
            question: '',
            question_private: 0,
            source: null,
            right: 1,
            stream_url: null,
            stream_url2: null,
            event_ended: false,
            right_size: '50%',
            show_poster: true,
            description_window_visible: false
        }
    },
    filters: {
        time(value) {
            return value.substr(11);
        },
    },
    props: {
        single: {
            type: Object,
            default() {
                return {};
            }
        },
        hide_comment_window:{
            type:Boolean,
            default(){
                return false;
            }
        },
        flv:{
          type:Boolean,
            default(){
                return false;
            }
        },
        closed: {
            type: Boolean,
            default() {
                return false;
            }
        },
        comments: {
            type: Array,
            default() {
                return [];
            }
        },
        self: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    methods: {
        dividerStartMove(e) {

            const vm = this;
            const dx = e.clientX;//当你第一次单击的时候，存储x轴的坐标。//相对于浏览器窗口
            const right_width = this.$refs['right'].offsetWidth;
            const frame_width = this.$refs['video_panel'].offsetWidth;
            const mouseMoveOver = function (ev) {
                const diff = ev.clientX - dx;//移动的距离（向左滑时为负数,右滑时为正数）
                const width = right_width - diff;
                vm.right_size = (width / frame_width) * 100 + "%";
                ev.preventDefault();
                ev.stopPropagation();
            }
            const mouseUp = function (ev) {
                document.removeEventListener("mousemove", mouseMoveOver);
                document.removeEventListener("mouseup", mouseUp);
                ev.preventDefault();
                ev.stopPropagation();
            }
            document.addEventListener("mousemove", mouseMoveOver);
            document.addEventListener("mouseup", mouseUp);

        },
        switchTabComment() {
            this.tab = 0;
        },
        switchTabInput() {
            this.tab = 1;
        },
        sourceSelect(item) {
            this.source = item;
            localStorage.setItem('PLAY_SOURCE', this.source);
            this.stream_url = this.getStream();
            this.stream_url2 = this.getStream(2);
            this.$emit('source', item);
        },
        languageSelect() {
            this.setLanguage(this.english ? 0 : 1);
        },
        toggleRight() {
            this.right = !this.right;
        },
        getStream(index) {
            let out = "";
            for (let i = 0; i < this.single.hls.length; i++) {
                if (index && this.single.hls2) {
                    if (this.single.hls2[i].english === this.source) {
                        out = this.single.hls2[i].url;
                        break;
                    }
                } else if (!index) {
                    if (this.single.hls[i].english === this.source) {
                        out = this.single.hls[i].url;
                        break
                    }
                }
            }
            return out;
        },
        checkClosed() {
            const now = Math.floor(new Date().getTime() / 1000);
            this.event_ended = now > this.single.end_timestamp;
            if (this.event_ended) {
                this.show_poster = true;
            }
        },
        removeTimer() {
            if (this.timer) {
                clearInterval(this.timer);
                this.timer = 0;
            }
        },
        sendComment() {
            if (this.question && this.user.token && this.user.token.length) {
                this.$api('Program.Comment', {
                    id: this.single.id,
                    uuid: this.single.uuid,
                    comment: {text: this.question, private: this.question_private ? 1 : 0}
                }, 1).then(res => {
                    this.$emit('comments', res.data.data);
                    this.question = '';
                    this.tab = 1;
                    //this.$notify.success(this.$t('MESSAGE.COMMENT-SENT'))
                });
            }
        },
        jumpToSignIn() {
            console.log(this.$route);
            this.$router.replace("/sign-in?_t=" + encodeURIComponent(this.$route.fullPath))
        }
    },
    mounted() {
        const source = localStorage.getItem('PLAY_SOURCE');
        if (source) {
            for (let i = 0; i < this.edge.length; i++) {
                if (source === this.edge[i].english) {
                    this.source = source;
                    break;
                }
            }
        }
        if (!this.source) {
            this.source = this.edge[0].english;
            localStorage.setItem('PLAY_SOURCE', this.source);
        }
        this.$emit('source', this.source);
        if (this.closed) {
            this.stream_url = this.single.dvr_file;
        } else {
            this.stream_url = this.getStream();
            if (this.single.hls2) {
                this.stream_url2 = this.getStream(2);
            } else {
                this.stream_url2 = null;
            }
        }
        if (!this.closed) {
            const vm = this;
            this.timer = setInterval(() => {
                vm.checkClosed();
            }, 1000);
        }
    },
    computed: {
        edge() {
            return this.single.hls.map(i => {
                return {name: i.name, english: i.english};
            });
        }
    },
    beforeDestroy() {
        this.removeTimer();
    },
    watch: {
        comments: {
            handler() {
                this.$nextTick(() => {
                    const div = this.$refs['comment_window'];
                    if (div) div.scrollTop = div.scrollHeight;
                });
            },
            immediate: true,
        },
        self: {
            handler() {
                this.$nextTick(() => {
                    const div = this.$refs['self_window'];
                    if (div) div.scrollTop = div.scrollHeight;
                });
            },
            immediate: true,
        }
    }
}

</script>

<style lang="scss">
.dark-description {
    /* 基础表格样式 */
    table {
        width: 100%;
        #border-collapse: collapse; /* 折叠边框，使其看起来更紧凑 */
        margin: 10px 0; /* 添加一些外边距 */
        th {
            background-color: #424242; /* 轻灰色背景 */
            color: #fff; /* 字体颜色 */
            font-weight: bold; /* 字体加粗 */
            text-align: left; /* 文本左对齐 */
            padding: 5px; /* 添加一些内边距 */
            border: 1px solid #666; /* 底部边框 */
        }

        /* 表格体样式 */
        td {
            padding: 5px; /* 添加一些内边距 */
            border: 1px solid #666; /* 底部边框 */
        }

        /* 鼠标悬停行的样式 */
        tr:hover {
            background-color: #626262; /* 悬停时的背景颜色 */
        }
    }
}

.el-dialog__wrapper {
    &.dark {
        .el-dialog {
            color: white;
            background: #333333 !important;

            .el-dialog__header {
                border-bottom: 1px solid #666;

                .el-dialog__title {
                    color: white;
                }
            }

            .el-dialog__body {
                height: calc(85vh - 300px);
                overflow: auto;
                color: white;
            }

            .el-dialog__footer {
                border-top: 1px solid #666;
            }
        }
    }
}


</style>
