<template>
    <div class="wh-100 mobile-portal">
        <section class="header" style="height: 92px;background-color: #303d4a">
            <div class="header-wrap p-0 d-flex justify-content-between align-items-center mt-1">
                <div class="logo" style="height: 45px;" >
                    <img src="/assets/logo.png" alt="" class="responsive-image">
                </div>
            </div>
            <div class="header-wrap px-1 text-white position-relative" style="height: 46px;">
                <el-tabs v-model="tab" @tab-click="handleTabChange" >
                    <el-tab-pane label="我的申请" name="list"></el-tab-pane>
                    <el-tab-pane label="历史记录" name="history"></el-tab-pane>
                    <el-tab-pane :label="single.id ? (single.disabled ? '查看申请':'编辑申请') : '新建申请'"
                                 name="application" v-if="tab==='application'">
                    </el-tab-pane>
                </el-tabs>
                <nut-button type="default" shape="circle"
                            v-if="tab!=='application'"
                            small class="application-button" @click="handleEdit(0)">
                    <el-icon name="plus"></el-icon>
                </nut-button>
            </div>
        </section>
        <section class="content-wrap">
            <div v-if="tab==='list' || tab==='history'" class="d-flex h-100 flex-column flex-shrink-0">
                <nut-buttongroup class="flex-shrink-0" v-if="tab==='list'">
                    <nut-button type="light" v-for="(x,i) in status" :key="i" style="padding: 0"
                                :class="status_index===i?'active':''" @click="handleFilterChange(i)">
                        {{ x }}
                    </nut-button>
                </nut-buttongroup>
                <div class="flex-grow-1 mt-2 application-list" style="font-size: 14px; padding: 0 10px;overflow: auto">
                    <nut-infiniteloading class="event-card-list"
                                         style="padding: 0; flex-direction: column"
                                         @loadmore="onInfinite"
                                         is-show-mod
                                         :has-more="isHasMore"
                                         :is-loading="isLoading"
                                         loading-txt="Loading..."
                                         :use-window="false"
                                         :threshold="10">
                        <section v-if="records.length === 0" class="text-center mt-3 text-muted">
                            没有相关任何申请记录
                        </section>
                        <section class="application-item d-flex" v-for="x in records" :key="x.id">
                            <div class="flex-shrink-0 flex-grow-0 text-small" style="width: 40px;padding: 5px">#{{
                                    x.id
                                }}
                            </div>
                            <div class="flex-grow-1 overflow-hidden" style="padding: 5px;">
                                <div class="">{{ x.time }}</div>
                                <div class="">
                                    <div class="font-weight-bold text-ellipsis overflow-hidden text-nowrap ">{{
                                            x.topic
                                        }}
                                    </div>
                                </div>
                                <div>{{ x.location }}</div>
                                <div :class="{'text-primary':x.status === 1}">{{ status[x.status + 1] }}</div>
                            </div>
                            <div class="flex-shrink-0" style="width: 50px;">
                                <div class="h-100 text-center d-flex flex-column justify-content-center"
                                     style="background-color: #dddddd; font-size: 14px;" @click="handleEdit(x.id)">查看
                                </div>
                            </div>
                        </section>
                        <div slot="loading" class="w-100 text-center text-muted ">Loading ...</div>
                        <div slot="unloadMore" class="w-100 text-center text-muted " v-show="records.length">
                            {{ $t('TITLE.NO-MORE') }}
                        </div>
                    </nut-infiniteloading>
                </div>
            </div>
            <div v-else-if="tab==='application'"
                 style="font-size: 14px;  height: 100%; overflow: auto">
                <div class="application-form ">
                    <nut-form-editor target="picture_alert" :fields="fields"/>
                    <nut-form-editor v-model="single.topic" target="topic" :fields="fields"
                                     :disabled="single.disabled"/>
                    <nut-form-editor v-model="single.date" target="date" :fields="fields" :disabled="single.disabled"/>
                    <nut-form-editor v-model="single.start_time" target="start_time" :fields="fields"
                                     :disabled="single.disabled"/>
                    <nut-form-editor v-model="single.end_time" target="end_time" :fields="fields"
                                     :disabled="single.disabled"/>
                    <nut-form-editor v-model="single.location" target="location" :fields="fields"
                                     :disabled="single.disabled"/>
                    <nut-form-editor v-model="single.picture" target="picture" :fields="fields"
                                     :disabled="single.disabled"/>
                    <nut-form-editor v-model="single.scope" target="scope" :fields="fields" :required="true"
                                     :disabled="single.disabled"/>
                    <nut-form-editor v-model="single.playback_method" target="playback_method" :fields="fields" :required="true"
                                     :disabled="single.disabled"/>
                    <nut-form-editor target="直播简介" :required="true"/>
                    <nut-form-editor v-model="single.description" target="description" :fields="fields"
                                     :disabled="single.disabled"/>
                    <nut-form-editor v-model="single.assistants" target="assistants" :fields="fields"
                                     :disabled="single.disabled"/>
                    <nut-form-editor :value="single.wechat_push" target="wechat_push" :fields="fields"
                                     :disabled="single.disabled" @input="handleWechatPushEnable"/>
                    <nut-form-editor v-if="single.wechat_push" v-model="single.wechat_push_date"
                                     target="wechat_push_date" :fields="fields" :disabled="single.disabled"/>
                    <nut-form-editor v-if="single.wechat_push" v-model="single.wechat_push_time"
                                     target="wechat_push_time" :fields="fields" :disabled="single.disabled"/>
                    <nut-form-editor v-if="single.wechat_push" v-model="single.wechat_push_scope"
                                     target="wechat_push_scope" :fields="fields" :disabled="single.disabled"/>

                </div>
                <div class="application-form bg-info pt-1 mt-3">
                    <nut-form-editor v-model="single.mobile" target="mobile" :fields="fields"
                                     :disabled="single.disabled"/>
                    <div class="form-item pb-3 pt-4" v-if="!single.disabled">
                        <nut-button small type="" class="mr-3" @click="submitApprove">提交审批</nut-button>
                        <nut-button small type="default" class="mr-3" @click="submitDraft">保存草稿</nut-button>
                        <nut-button small type="danger" class="mr-3" @click="submitDelete" v-if="single.id">删除申请
                        </nut-button>
                    </div>
                    <div v-else class="form-item pb-3 pt-4" >
                        <nut-button small type="" class="mr-3" @click="handleCancelApplication">返回列表</nut-button>
                    </div>
                </div>
                <div class="application-form mt-1 bg-light pb-4 mt-3">
                    <nut-form-editor target="审批信息"/>
                    <div class="text-left text-small px-3">{{ status[parseInt(single.status) + 1] }}
                        {{ single.reason }}, {{ fields['reason'].placeholder }}
                    </div>

                    <nut-form-editor target="节目信息" >
                        <i class="el-icon-s-claim text-primary" style="font-size:16px; cursor:pointer" @click="copyClipboard" v-if="single.program_info"></i>
                    </nut-form-editor>
                    <div class="text-left text-muted text-small px-3" v-if="!single.program_info">节目未创建</div>
                    <ul v-else class="program-info">
                        <li  v-for="(x,key) in single.program_info" :key="key">
                            <div class="font-weight-bold" style="flex:0 0 6em">{{ key }}:</div>
                            <div class="flex-grow-1" >
                                <a :href="x" v-if="['play_url','dvr_file'].indexOf(key)!== -1">{{x}}</a>
                                <span v-else>{{ x }}</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import "@/plugins/nutui"
import CommonMixin from "@/common/mixins/common-mixin";
import NutFormEditor from "@/components/nut-form-editor.vue";

export default {
    name: "application",
    components: {NutFormEditor},
    mixins: [CommonMixin],
    data() {
        return {
            tab: 'list',
            old_tab: 'list',
            status: ['所有', '草稿', '已提交', '已通过', '未通过'],
            status_index: 0,
            isHasMore: true,
            isLoading: false,
            records: [],
            fields: {},
            single: {},
            page: {
                total: 0,
                display: 1,
                current: 0,
                count: 0,
                size: 20
            },
            search: {
                filters: [],
                page: 1,
                size: 20,
            },
            debounced:0,
        }
    },
    methods: {
        onInfinite() {
            if (this.debounced) {
                clearTimeout(this.debounced);
                this.debounced = 0;
            }
            if (this.isLoading) return;
            if (!this.isHasMore) return;
            this.isLoading = true;
            this.debounced = setTimeout(()=>{
                this.search.page = this.page.current + 1;
                this.loadList();
            },100)

        },
        loadList() {
            const search = Object.assign({}, this.search)
            if (this.tab === 'list') {
                search.filters[0] = 'S' + this.status_index;
                search.filters[1] = 'H0';
            } else {
                search.filters[1] = 'H1';
                search.filters[0] = 'S0';
            }
            this.$api("Application.List", search, 1).then(res => {
                const data = res.data.data;
                this.page = Object.assign({}, this.page, res.data.data.page);
                this.isHasMore = this.page.current < this.page.pages;
                if (this.page.current === 1) {
                    this.records = data.data;
                } else {
                    data.data.forEach(i=>this.records.push(i));
                }
                this.isLoading = false;
            }).catch(() => {
                this.isLoading = false;
            });
        },
        handleFilterChange(i) {
            this.status_index = i;
            this.handleTabChange();
        },
        handleTabChange() {
            this.search.page = 1;
            this.isHasMore = true;
            this.loadList();
        },
        handleEdit(id) {
            this.$api('Application.Get', {id: id}, 1).then(res => {
                this.single = res.data.data.data;
                this.fields = res.data.data.fields;
                this.old_tab = this.tab;
                this.tab = 'application';
                if (this.single.id === 0) {
                    const date = new Date();
                    date.setDate(date.getDate() + 1);
                    this.single.date = date.Format('yyyy-MM-dd');
                    this.single.start_time = '09:00';
                    this.single.end_time = '12:00';
                }
            });
        },
        handleWechatPushEnable(e) {
            if (e) { // enabled
                if (!this.single.wechat_push_date || !this.single.wechat_push_time) {
                    this.single.wechat_push_date = this.single.date;
                    this.single.wechat_push_time = this.single.start_time;
                }
                if (!this.single.wechat_push_scope) {
                    this.single.wechat_push_scope = [this.fields['wechat_push_scope'].options[0]]
                }
            }
            this.$set(this.single, 'wechat_push', e);
        },
        handleCancelApplication() {
            if (this.single.disabled) {
                this.tab = this.old_tab;
            } else {
                const vm = this;
                this.$dialog({
                    customClass:'application dialog',
                    title: '放弃修改',
                    content: '确定放弃修改吗？',
                    onOkBtn() {  //确定按钮点击事件
                        vm.tab = vm.old_tab;
                        this.close(); //关闭对话框
                    },
                })
            }
        },
        submitForm(draft) {
            const single = this.single;
            single['draft'] = draft ? 1 : 0;
            single['wechat_push'] = single.wechat_push ? 1 : 0;
            this.$api("Application.Edit", single, 1).then(() => {
                this.tab = this.old_tab;
                this.search.page = 1;
                this.isHasMore = true;
                this.loadList();
            });
        },
        submitApprove() {
            this.submitForm(false);
        },
        submitDraft() {
            this.submitForm(true)
        },
        submitDelete() {
            const vm = this;
            this.$dialog({
                customClass:'application',
                title: '删除申请',
                content: '确定删除此申请吗？',
                onOkBtn() {  //确定按钮点击事件
                    vm.$api('Application.Delete', vm.single, 1).then(() => {
                        vm.tab = vm.old_tab;
                        vm.search.page = 1;
                        vm.isHasMore = true;
                        vm.loadList();
                        this.close(); //关闭对话框
                    }).catch(()=> {
                        this.close();
                    });
                },
            })
        },
        async copyClipboard() {
            if (this.single.program_info) {
                let info = '';
                for (const [key, value] of Object.entries(this.single.program_info)) {
                    info += `${key}: ${value}\n`;
                }
                try {
                    await navigator.clipboard.writeText(info);
                    this.$toast.success('信息已复制到剪贴板');
                } catch (e) {
                    this.$toast.error('信息复制到剪贴板失败');
                }
            }
        }
    },
    computed: {},
    mounted() {
        this.loadList();
    },
}
</script>

<style lang="scss">
@import "~@/views/scss/mobile-public.scss";
</style>
