<template>
    <div class="console-page">
        <div class="panel-left">
            <div class="video">
                <div class="v-100 h-100">
                    <video-player :source="stream_url" :poster="single.picture_url" controls></video-player>
                </div>
            </div>
            <div class="controls">
                <div class="p-2" style="background-color: #d9e5f1">
                    <div class="d-flex">
                        <div><b class="mr-2">流:</b></div>
                        <el-radio-group style="margin-top: 4px" v-model="target_stream" size="mini">
                            <el-radio :label="0">{{ single.source }}</el-radio>
                            <el-radio :label="1" v-if="single.source2">{{ single.source2 }}</el-radio>
                        </el-radio-group>
                    </div>
                    <div class="d-flex">
                        <div><b class="mr-2">源:</b></div>
                        <el-radio-group style="margin-top: 4px" v-model="source" size="mini">
                            <el-radio v-for="x in edge" :label="x.english" :key="x.english">
                                {{ x.name }}
                            </el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <div class="control-left px-2 ">
                    <div class="upper">
                        <p class=""><b>{{ single.subject }}</b></p>
                        <p><span class="text-small"> {{ single.good_time }} </span>
                            <span
                                :class="status==='正在直播'?'text-danger':(status==='未开始'?'text-primary':'text-info')">
                                {{ status }}
                            </span>
                        </p>
                        <p>{{ single.teacher_name }} | {{ single.city_name }} | {{ single.department_name }}</p>
                    </div>
                    <div class="line"></div>
                    <div class="down">
                        <p><b>访问人次:</b>{{ single.pov ? single.pov.pov_total : 0 }}
                            | <b>当前在线:</b>{{ total_online }} 人</p>
                        <p><b>服务器:</b>
                            <span v-for="x in edge_online" :key="x.english" class="d-inline-block mr-3">
                                <i style="font-style: normal;font-weight: 500;">{{ x.name }}: </i> {{ x.online }}
                            </span>
                        </p>
                    </div>
                </div>
                <div class="line"></div>
                <div class="control-right px-2">
                    <div class="upper">
                        <p class="text-ellipsis"><b>播放:</b>
                            <a :href="single.play_url" target="_blank"> <i class="el-icon-link"></i> 跳转</a>
                            | <b> 密码:</b> <span><i class="text-info">{{ single.passcode || "无" }}</i> </span>
                        </p>
                    </div>
                    <div class="upper">
                        <p class="text-ellipsis"><b>推流:</b><span class="text-small"> {{ rtmp_url }}</span></p>
                        <p class="text-ellipsis"><b>拉流:</b><span class="text-small"> {{ stream_url }}</span></p>
                    </div>
                </div>
            </div>
            <div class="control-bottom" style="border-top: 1px solid #ddd;background-color: #eeeeee">
                <div class="p-2">
                    <el-switch @change="updateAllowComment"
                               v-model="payload.allow_comment"
                               active-text="评论开启"
                               inactive-text="评论关闭"
                               active-value="1"
                               inactive-value="0"
                               class="mr-2">
                    </el-switch>
                    <div class="pull-right mb-2">

                        <el-button size="medium" type="info" plain @click="HandleStartChange">开始时间</el-button>
                        <el-button size="medium" type="warning" plain @click="HandleEndChange">结束时间</el-button>
                        <el-button size="medium" type="success" plain @click="HandleImportDB">转入数据库</el-button>
                        <el-button size="medium" type="danger" plain @click="HandlePauseChange">{{live_pause?"恢复直播":"暂停直播"}}</el-button>
                    </div>
                </div>

            </div>
        </div>
        <div class="panel-switch" ref="switch" @mousedown="switchStartMove"></div>
        <div class="panel-right wh-100" v-show="right" :style="{width:right_size}" ref="right">
            <div class="panel-right-table h-100">
                <comment-view :program="this.single" class="wh-100"></comment-view>
            </div>
        </div>
        <el-dialog title="修改时间" :visible.sync="change_time_dialog" width="450px">
            <div><b style="font-size: 1.2rem;">{{ payload.title }}</b></div>
            <el-row class="mt-4 bg-light p-3">
                <el-col :span="24">
                    <front-fake-form-item label="日期" :width="60">
                        <el-date-picker
                            v-model="payload.date"
                            type="date"
                            value-format="yyyy-MM-dd"
                            :placeholder='("选择日期")'
                        >
                        </el-date-picker>
                    </front-fake-form-item>
                </el-col>
                <el-col :span="24">
                    <front-fake-form-item label="时间" :width="60">
                        <el-time-select
                            value-format="hh:mm"
                            v-model="payload.time"
                            :picker-options="{ start: '00:00',step: '00:05',end: '23:45'}"
                            :placeholder='("选择时间")'
                        >
                        </el-time-select>
                    </front-fake-form-item>
                </el-col>
                <el-col :span="24">
                    <el-button icon="el-icon-alarm-clock" type="success" @click="setCurrentTime">设置为当前时间
                    </el-button>
                </el-col>
            </el-row>
            <div slot="footer" class="">
                <slot name="footer"></slot>
                <el-button @click.native="change_time_dialog=false">{{ '放弃' }}</el-button>
                <el-button type="primary" @click.native="timeChangeSubmit" :loading="process.loading">{{ ('提交') }}
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>

import CommonMixins from "@/common/mixins/common-mixin";
import "@/views/scss/console-live-page.scss";
import VideoPlayer from "@/components/video-player";
import FrontFakeFormItem from "@/components/front-fake-form-item";
import CommentView from "@/components/comment-view";


export default {
    name: "console-live-page",
    components: {CommentView, VideoPlayer, FrontFakeFormItem},
    mixins: [CommonMixins],
    data() {
        return {
            tab: 0,
            question: '',
            question_private: 0,
            source: null,
            right: 1,
            online_users: 1,
            event_ended: false,
            status: '',
            change_time_dialog: false,
            payload: {
                date: '',
                time: '',
                action: '',
                title: '',
                id: 0,
                allow_comment: this.single.allow_comment,
                uuid: this.single.uuid,
            },
            edge_online: [],
            ended: false,
            right_size: '50%',
            target_stream: 0,
            live_pause: false,
        }
    },
    filters: {
        time(value) {
            return value.slice(11);
        }
    },
    props: {
        single: {
            type: Object,
            default() {
                return {};
            }
        },
    },
    methods: {
        switchStartMove(e) {
            const vm = this;
            const dx = e.clientX;//当你第一次单击的时候，存储x轴的坐标。//相对于浏览器窗口
            const right_width = this.$refs['right'].offsetWidth;
            const mouseMoveOver = function (ev) {
                const diff = ev.clientX - dx;//移动的距离（向左滑时为负数,右滑时为正数）
                const width = right_width - diff;
                vm.right_size = (width / document.body.offsetWidth) * 100 + "%";
                ev.preventDefault();
                ev.stopPropagation();
            }
            const mouseUp = function (ev) {
                document.removeEventListener("mousemove", mouseMoveOver);
                document.removeEventListener("mouseup", mouseUp);
                ev.preventDefault();
                ev.stopPropagation();
            }
            document.addEventListener("mousemove", mouseMoveOver);
            document.addEventListener("mouseup", mouseUp);
        },
        sourceSelect() {
        },
        toggleRight() {
            this.right = !this.right;
        },
        getStream() {
            const source = this.single.sources[this.target_stream];
            if (source && source.hls) {
                const foundItem = source.hls.find(item => item.english === this.source);
                if (foundItem) {
                    return foundItem.url;
                }
            }
            return '';
        },
        checkClosed() {
            this.updateStatus();
        },
        updateStatus() {
            const now = Math.floor(new Date().getTime() / 1000);
            if (now <= this.single.start_timestamp) {
                this.status = '未开始';
                this.ended = false;
            } else if (now >= this.single.end_timestamp) {
                this.status = "已结束";
                this.ended = true;
            } else {
                this.status = "正在直播";
                this.ended = false;
            }
        },
        HandleStartChange() {
            let date = this.single.start_date;
            let time = this.single.start_time;

            this.payload.title = "修改开始时间";
            this.payload.date = date;
            this.payload.time = time;
            this.change_time_dialog = true;
            this.payload.action = 'start'
            this.payload.id = this.single.id;
        },
        HandleEndChange() {
            let date = this.single.end_date;
            let time = this.single.end_time;

            this.payload.title = "修改结束时间";
            this.payload.action = 'end'
            this.payload.date = date;
            this.payload.time = time;
            this.change_time_dialog = true;
            this.payload.id = this.single.id;
        },
        HandleImportDB() {
            this.$api('Admin.Program.Import', this.payload, 1).then(() => {
                this.$notice.success('数据转入完成!');
                this.change_time_dialog = false;
            })
        },
        setCurrentTime() {
            let now = new Date();
            const diff = 5 - now.getMinutes() % 5;
            if (diff !== 5) {
                now.setTime(now.getTime() + diff * 60000);
            }
            this.payload.date = now.Format('yyyy-MM-dd');
            this.payload.time = now.Format('hh:mm');
        },
        updateAllowComment() {
            this.payload.action = 'comment';
            this.timeChangeSubmit();
        },
        timeChangeSubmit() {
            this.$api('Admin.Program.Update', this.payload, 1).then(() => {
                this.$notice.success('参数设置完成!');
                this.$emit('reload');
                this.change_time_dialog = false;
            })
        },
        removeTimer() {
            if (this.timer) {
                clearInterval(this.timer);
                this.timer = 0;
            }
        },
        HandlePauseChange(){
            this.live_pause = !this.live_pause;
        }
    },
    mounted() {
        this.source = this.edge[0].english;
        const vm = this;
        this.timer = setInterval(() => {
            vm.checkClosed();
        }, 1000);
        this.updateStatus();
    },
    computed: {
        edge() {
            return this.single.sources[0].url;
        },
        stream_url() {
            if (this.single) {
                const source = this.single.sources[this.target_stream];
                if (source && source.url) {
                    const foundItem = source.url.find(item => item.english === this.source);
                    return foundItem ? foundItem.url : '';
                }
                console.log('no source', this.single.sources[0]);
                return '';
            } else {
                console.log('no ready');
                return '';
            }
        },
        rtmp_url() {
            if (this.single) {
                const source = this.single.sources[this.target_stream];
                return source.rtmp;
            } else {
                return null;
            }
        },
        total_online() {
            return this.single.online.online || 0;
        },
    },
    beforeDestroy() {
        this.removeTimer();
    },
    watch: {
        single: {
            handler(newVal) {
                let data = [];
                const source = newVal.sources[0];
                if (newVal.online && source) {
                    source.url.forEach(x => {
                        data.push({name: x.name, english: x.english, online: this.single.online[x.english] || 0});
                    });
                }
                this.$set(this, 'edge_online', data);
            },
            deep: true,
            immediate: true,
        }
    }
}

</script>
